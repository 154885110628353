<template>
  <div>
    <b-card>
      <table-render
        v-if="!loading.first"
        id="table_indemnificationsList"
        :rows="rows"
        :schema="schema"
        :loading="loading.indemnifications"
        :actions="actions"
      >
       <!-- Admin send email -->
       <template #notify="scope">
          <b-button @click="confirmSendEmailNotification(scope.rowdata.id)" variant="link" class="p-0"><feather-icon icon="MailIcon" size="16"/></b-button>
      </template>
      </table-render>
      <pagination
        v-if="!loading.first"
        :pagination="pagination"
        :noDigits="false"
        :showSize="true"
        :disabled="loading.indemnifications"
      />
      <div class="table-render-skeleton" v-show="loading.first">
        <b-skeleton-table
        :rows="pagination.limit || 10"
        :columns="schema.length || 10"
        :table-props="{}"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
  props: ['company_id'],
  data() {
    return {
      loading: {
        first: true,
        indemnifications: true
      },
      rows: [],
      schema: [],
      actions: [],
      pagination: { page: 1, limit: 10, total: 0, loading: false }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      indemnificationsDataCompany: 'getIndemnificationsDataCompany'
    })
  },
  watch: {
    mySession () {
      this.setInitialData()
    },
    indemnificationsDataCompany() {
      this.rows = this.indemnificationsDataCompany.rows.map(indemnification => {
        return {
          ...indemnification,
          totalParse: this.$options.filters.currency(indemnification.totalAmountOriginal, 0, '$') || '--',
          debtParse: this.$options.filters.currency(indemnification.debtAmount, 0, '$'),
          month: this.$options.filters.monthName(indemnification.date)
        }
      })
      this.loading.first = false
      this.loading.indemnifications = false
      this.pagination.page = this.indemnificationsDataCompany.pagination.current_page
      this.pagination.total = this.indemnificationsDataCompany.pagination.total_items
      this['pagination.limit'] = this.indemnificationsDataCompany.pagination.total_pages
    },
    'pagination.page'() {
      this.getIndemnificationsData()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getIndemnificationsData()
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.schema = [
        { label: 'Número', key: 'correlationNumber'},
        { label: 'Mes', key: 'month'},
        { label: 'Fecha emisión', key: 'date'},
        { label: 'Monto total', key: 'totalParse'},
        { label: 'Deuda', key: 'debtParse', doubleDash: true },
        { label: 'Documento', key: 'actions', class: ['text-center'] }
      ]
      //Vistas solo para adm
      if (['superadmin', 'admin'].includes(this.$session.get('cas_user').role)) {
        this.schema.push(
          { label: 'Notificar', key: 'notify', class: ['text-center'], useSlot: true}
        )
      }
      this.actions = [
        { action: id => this.downloadIndemnification(id), icon: 'FileTextIcon', color: 'primary', text: 'Descargar pdf'}
      ]
      if (!Object.keys(this.indemnificationsDataCompany).length) this.getIndemnificationsData()
    },
    confirmSendEmailNotification (indemnifId) {
      this.$confirm(this.$t('msg-pregunta-enviar-notificacion-indemnizacion'), () => this.sendEmailNotification(indemnifId), '¿Está seguro que desea enviar la notificación?')
    },
    sendEmailNotification (indemnificationId) {
      Swal.fire({
        title: 'Enviando',
        showConfirmButton: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })
      const params = {
        company_id: this.company_id,
        indemnification_id: indemnificationId
      }
      this.$store.dispatch('fetchServiceStatusOnError', {
        name: 'sendEmailIndemnificationsDataCompany',
        params,
        onSuccess: () => this.$success(this.$t('msg-exito-enviar-notificacion')),
        onError: (err) => this.handleIndemnificationsErrors(err, 'Error al enviar la notificación')
      })
    },
    downloadIndemnification (id) {
      const data = this.rows.find(el => el.id === id)
      window.open(data.pdfLink, '_blank')
    },
    getIndemnificationsData() {
      this.loading.indemnifications = true
      const queryParams = {
        page: this.pagination.page,
        size: this.pagination.limit
      }
      const params = {
        company_id: this.company_id
      }
      this.$store.dispatch('fetchServiceStatusOnError', { name: 'getIndemnificationsDataCompany', queryParams, params, onError: err => this.handleIndemnificationsErrors(err, 'Error en la pestaña de Indemnizaciones') })
    },
    handleIndemnificationsErrors(error, title) {
      const {subtitle, detail} = this.handlerStandartError(error)
      this.$alert(`${subtitle}<small>${detail}</small>`, null, title)
    }
  }
}
</script>

<style lang="scss">
</style>
