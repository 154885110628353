<template>
  <b-modal
    id="modal-detail-related-invoice"
    :title="$t('Detalle de factura')"
    modal-class="dialog-900"
    centered
    no-close-on-backdrop
    @close="close"
  >
    <div class="row" v-if="dataInvoice">
      <div class="col-sm-12 col-md-4 mb-1 container-info">
        <label><b>{{$t('Cliente')}}</b></label>
        <span>{{dataInvoice.client.name || '--'}}</span>
      </div>
      <div class="col-sm-12 col-md-4 mb-1 container-info">
        <label><b>{{$t('Identificador Fiscal')}}</b></label>
        <span>{{dataInvoice.client.taxId || '--'}}</span>
      </div>
      <div class="col-sm-12 col-md-4 mb-1 container-info">
        <label><b>{{$t('Número')}}</b></label>
        <span>{{dataInvoice.number || '--'}}</span>
      </div>
      <div class="col-sm-12 col-md-4 mb-1 container-info">
        <label><b>{{$t('Fecha emisión')}}</b></label>
        <span>{{dataInvoice.issueDate || '--'}}</span>
      </div>
      <div class="col-sm-12 col-md-4 mb-1 container-info">
        <label><b>{{$t('Fecha vencimiento')}}</b></label>
        <span>{{dataInvoice.dueDate || '--'}}</span>
      </div>
      <div class="col-sm-12 col-md-4 mb-1 container-info">
        <label><b>{{$t('Moneda')}}</b></label>
        <span>{{dataInvoice.currency || '--'}}</span>
      </div>
      <div class="col-sm-12 col-md-4 mb-1 container-info">
        <label><b>{{$t('Monto neto')}}</b></label>
        <span>{{this.$options.filters.currency(dataInvoice.net, 0, '$')|| '--'}}</span>
      </div>
      <div class="col-sm-12 col-md-4 mb-1 container-info">
        <label><b>{{$t('Impuestos')}}</b></label>
        <span>{{this.$options.filters.currency(dataInvoice.taxes, 0, '$') || '--'}}</span>
      </div>
      <div class="col-sm-12 col-md-4 mb-1 container-info">
        <label><b>{{$t('Total')}}</b></label>
        <span>{{this.$options.filters.currency(dataInvoice.total, 0, '$') || '--'}}</span>
      </div>
      <div class="col-sm-12 col-md-4 mb-1 container-info">
        <label><b>{{$t('Comentario')}}</b></label>
        <span>{{dataInvoice.notes || '--'}}</span>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="success" @click="goTo('pdf')">
          <feather-icon icon="FileTextIcon"/> PDF
        </b-button>
        <b-button variant="success mx-1" @click="goTo('xml')">
          <feather-icon icon="FileIcon"/> XML
        </b-button>
        <b-button variant="primary" @click="goTo('url')">
          <feather-icon icon="LinkIcon"/> Duemint
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'modal-detail-related-invoice',
  props: ['dataInvoice'],
  methods: {
    goTo(url) {
      window.open(this.dataInvoice[url], '_blank')
    },
    close() {
      this.$bvModal.hide('modal-detail-invoice')
    }
  }
}
</script>
