<template>
<div>
    <b-card>
    <table-render
        v-if="!loading.first"
        id="table_notesList"
        :rows="rows"
        :schema="schema"
        :loading="loading.notes"
    />
    <pagination
        v-if="!loading.first"
        :pagination="pagination"
        :noDigits="false"
        :showSize="true"
        :disabled="loading.notes"
    />
    <div class="table-render-skeleton" v-show="loading.first">
        <b-skeleton-table
        :rows="pagination.limit || 10"
        :columns="schema.length || 10"
        :table-props="{}"
        />
    </div>
    <modal-detail-related-invoice :dataInvoice="dataInvoice"></modal-detail-related-invoice>
    </b-card>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalDetailRelatedInvoice from './ModalDetailRelatedInvoice.vue'
import BillingService from '../../billing.service'

export default {
  props: ['company_id'],
  components: { ModalDetailRelatedInvoice },
  data() {
    return {
      dataInvoice: '',
      loading: {
        first: true,
        notes: true
      },
      rows: [],
      allInvoicesData: [],
      schema: [],
      pagination: { page: 1, limit: 10, total: 0, loading: false },      
      environment: null,
      billingService: new BillingService(this)
    }
  },

  computed: {
    ...mapGetters({
      mySession: 'getSession',
      notesData: 'getNotesBillingDataCompany'
    })
  },

  watch: {
    mySession () {
      this.setAllData()
    },
    notesData() {
      this.rows = this.notesData.rows.map(notesData => {
        return {
          ...notesData,
          totalParse: this.$options.filters.currency(notesData.totalAmount, 0, '$')
        }
      })
      this.loading.first = false
      this.loading.notes = false
      this.pagination.page = this.notesData.pagination.current_page
      this.pagination.total = this.notesData.pagination.total_items
      this['pagination.limit'] = this.notesData.pagination.total_pages
    },
    'pagination.page'() {
      this.getNotesData()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getNotesData()
    }
  },
  mounted() {
    this.environment = this.billingService.setEnvironment()
    this.setAllData()
  },
  methods: {
    setAllData() {
      if (this.mySession.id) {
        this.setInitialData()
        this.getNotesData()
      }
    },
    setInitialData() {
      this.schema = [
        { label: 'Número', key: 'noteNumber'},
        { label: 'Tipo', key: 'documentTypeName'},
        { label: 'Fecha emisión', key: 'issueDate'},
        { label: 'Monto total', key: 'totalParse'},
        { label: 'Factura asociada', key: 'document.invoiceNumber'}
      ]
    },
    getNotesData() {
      this.loading.notes = true
      const queryParams = {
        page: this.pagination.page,
        size: this.pagination.limit
      }
      const params = {
        company_id: this.company_id
      }
      this.$store.dispatch('fetchServiceStatusOnError', { name: 'getNotesBillingDataCompany', queryParams, params, onError: (err) => this.onErrorGetNotesData(err) })
    },
    onErrorGetNotesData (error) {
      const {subtitle, detail} = this.handlerStandartError(error)
      this.$alert(`${subtitle}<small>${detail}</small>`, null, 'Error en la pestaña de Notas de crédito y débito')
    }
  }
}
</script>

<style lang="scss">
</style>
