<template>
  <b-modal
    id="modal-send-notification"
    :title="$t('Notificar')"
    :ok-title="$t(loading ? 'Enviando notificación' : 'Confirmar')"
    ok-only
    ok-variant="success"
    modal-class="dialog-600"
    centered
    no-close-on-backdrop
    @close="close"
    @ok="ok"
    :ok-disabled="loading"
  >
    <p class="text-center">Confirme si desea enviar la notificación por correo del documento <b>Nº {{dataInvoice.invoiceNumber}}</b></p>
    <form-render v-if="dataInvoice" :form.sync="form" :fields="fields" @send="sendNotification" :key="keyFormRender" containerButtonsClass="col-sm-12" ref="formSendNotification">
    </form-render>

    <div v-if="!dataInvoice">
      <b-skeleton width="30%"/>
      <b-skeleton type="input"/>
      <b-skeleton width="30%" class="mt-1"/>
      <b-skeleton type="input" height="100px"/>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'modal-send-notification',
  props: ['dataInvoice', 'company_id'],
  data() {
    return {
      form: {},
      fields: [],
      loading: false,
      keyFormRender: 0
    }
  },
  watch: {
    dataInvoice () {
      this.setFormData()
      this.keyFormRender++
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    ok (bvModalEvent) {
      this.$refs.formSendNotification.checkForm()
      bvModalEvent.preventDefault()
    },
    setInitialData() {
      this.fields = [
        {fieldType: 'FieldInput', name: 'new_subject', label: 'Asunto', containerClass: 'col-sm-12 container-info font-weight-bold', validation: 'required', clearable: false},
        {fieldType: 'FieldTextarea', name: 'new_body', label: 'Cuerpo', containerClass: 'col-sm-12 container-info font-weight-bold', validation: 'required', clearable: false, rows: 7}
      ]
      this.setFormData()
      this.keyFormRender++
    },
    setFormData () {
      this.form = {
        new_subject: `Informe detallado de factura Identificador Fiscal. ${this.dataInvoice.taxIdentificationNumber} Documento No. ${this.dataInvoice.invoiceNumber}`,
        new_body: `Estimados,\n\nEstamos adjuntando detalle de los movimientos correspondientes al mes de ${this.$options.filters.monthName(this.dataInvoice.issueDate)}, ${this.getYear(this.dataInvoice.issueDate)}`
      }
    },
    getYear (date) {
      const newDate = new Date(date)
      return newDate.getFullYear()
    },
    sendNotification(data) {
      this.loading = true
      const params = {
        company_id: this.company_id,
        invoice_number: this.dataInvoice.invoiceNumber
      }
      const queryParams = {
        company_id: this.company_id,
        new_subject: data.new_subject,
        new_body: data.new_body,
        number: this.dataInvoice.invoiceNumber
      }
      this.$store.dispatch('fetchServiceStatusOnError', { name: 'sendEmailBillingDataCompany', queryParams, params, onSuccess: (data) => this.onSuccessSendNotification(data), onError: err => this.handleNotificationError(err) })
    },
    onSuccessSendNotification (data) {
      this.loading = false
      this.$success(this.$t('msg-exito-enviar-notificacion', data.message))
      this.close()
    },
    handleNotificationError (error) {
      this.loading = false
      const {subtitle, detail} = this.handlerStandartError(error)
      this.$alert(`${subtitle}<small>${detail}</small>`, null, 'Error al enviar la notificación')
    },
    close() {
      this.$bvModal.hide('modal-send-notification')
      this.setFormData()
    }
  }
}
</script>
