<template>
  <div>
    <b-card>
      <b-button variant="outline-primary" class="push-right rounded-pill" v-show="tabIndex === 2"  @click="goToHistoryAjust()">
        <feather-icon icon="ClockIcon" /> Historial de ajustes
      </b-button>
      <template v-if="!hasBillingData">
        <div class="d-flex">
          <b-skeleton width="15%" height="2.3rem" class="mb-05"/>
          <b-skeleton width="23%" height="2.3rem" class="mb-05 mx-1"/>
          <b-skeleton width="18%" height="2.3rem" class="mb-05 mr-1"/>
          <b-skeleton width="25%" height="2.3rem" class="mb-05"/>
        </div>
        <div class="p-2">
          <div class="table-render-skeleton">
            <b-skeleton-table :rows="10" :columns="7" :table-props="{}" />
          </div>
        </div>
      </template>
      <b-tabs v-else align="left" class="tab-primary" v-model="tabIndex">
        <b-tab v-if="show.isBusinessDataAvailable">
          <template #title>
            <div class="tab-title">
            <feather-icon icon="FileTextIcon"/>
            <span>{{$t('Facturas')}}</span>
            </div>
          </template>
          <billing-list :company_id="company_id"></billing-list>
        </b-tab>
        <b-tab v-if="show.isBusinessDataAvailable">
          <template #title>
            <div>
            <feather-icon icon="FileTextIcon"/>
            <span> {{$t('Notas de crédito y débito')}}</span>
            </div>
          </template>
          <notes-list :company_id="company_id"></notes-list>
         </b-tab>
         <b-tab v-if="show.indemnifications && show.isBusinessDataAvailable">
          <template #title>
            <div>
            <feather-icon icon="FileTextIcon"/>
            <span> {{$t('Indemnizaciones')}}</span>
            </div>
          </template>
          <indemnifications-list :company_id="company_id"></indemnifications-list>
         </b-tab>
         <b-tab v-if="show.collections">
          <template #title>
            <div>
            <feather-icon icon="FileTextIcon"/>
            <span> {{$t('Recaudos contra entrega')}}</span>
            </div>
          </template>
          <collections-list :company_id="company_id"></collections-list>
         </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BillingList from './billing/BillingList.vue'
import NotesList from './notes/NotesList.vue'
import IndemnificationsList from './indemnifications/IndemnificationsList.vue'
import CollectionsList from './collections/CollectionsList.vue'
import BillingService from '../billing.service'


export default {
  components: { BillingList, NotesList, IndemnificationsList, CollectionsList },

  data () {
    return {
      company_id: this.$route.params?.id,
      show: {
        indemnifications: false,
        collections: false,
        isBusinessDataAvailable: false
      },
      hasBillingData: false,
      billingService: new BillingService(this),
      companyData: null,
      tabIndex: 0
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      hasBusinessData: 'getHasBusinessData'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.getCompanyData()
      else this.hasBillingData = true
    },
    hasBusinessData() {
      this.show.isBusinessDataAvailable = this.hasBusinessData
    }
  },
  mounted() {
    this.environment = this.billingService.setEnvironment()
    if (this.mySession.id) this.getCompanyData()
    this.$bvToast.toast('Por favor, espere un momento', {
      'title': 'Validando datos de la empresa',
      'variant': 'info',
      'id': 'bvToastBillingData'
    })
  },
  methods: {
    getCompanyData() {
      if (!this.companyData) this.$store.dispatch('fetchServiceStatusOnError',
        {
          name: 'getCompanyInfoBilling',
          params: { company_id: this.$route.params?.id },
          hideAlert: true,
          onSuccess: (response) => {
            this.$store.dispatch('changeValueHasBusinessData', response.data.hasBusinessData)
            this.callAllServices(response)
          },
          onError: (e) => this.onErrorBillingData(e)
        }
      )
      if (['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role))  this.show.indemnifications = true
    },
    goToHistoryAjust() {
      this.$router.push({ name: 'historial-ajustes',  params: { id: this.$route.params?.id }})
    },
    callAllServices(res) {
      this.companyData = res.data
      if (['co', 'mx'].includes(res.data.country.code.toLowerCase()) && ['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)) {
        this.show.collections = true
      } else if (!this.hasBusinessData) {
        const e = {
          title: 'No se puede listar facturas',
          detail: 'La compañía consultada no posee datos de facturación'
        }
        this.onErrorBillingData(e, false)
        return
      }
      this.$bvToast.toast('Por favor, espere un momento', {
        title: 'Obteniendo datos de facturación',
        variant: 'primary',
        id: 'bvToastBilling'
      })
      this.hasBillingData = true
    },
    onErrorBillingData(error, noBillingData = true) {
      (!noBillingData) ? this.$info(`${error.title}<br></r><small>${error.detail}</small>`, this.redirect, 'Aviso', '', false) : this.$alert(this.$t('msg-problema-cargar', {code: error?.message || JSON.stringify(error)}))
    },
    redirect () {
      const url = ['admin'].includes(this.$session.get('cas_user')?.role) ? '/manage-billing-data' : ''
      this.$router.push({path: url})
    }
  }
}
</script>

<style lang="scss">
#swal2-title{
  font-size: 1.5rem;
  margin-bottom: 0;
}

.billings-button {
  top: 5.5rem !important;
  right: 2rem !important;
  position: absolute !important;
  z-index: 9;
  display: flex;
  gap: 0.5rem;
}

</style>
