<template>
  <b-modal
    id="modal-detail-invoice"
    :title="$t('Detalle de factura')"
    modal-class="dialog-900"
    centered
    no-close-on-backdrop
    @close="close"
  >
    <div class="row" v-if="dataInvoice">
      <div
        v-for="data, index in detailInvoice" :key="index"
        class="col-sm-12 col-md-4 mb-1 container-info"
      >
        <label><b>{{data.title}}</b></label>
        <span v-if="data.useFilter">{{$options.filters.currency(data.text, 0, '$')}}</span>
        <span v-else>{{data.text}}</span>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button v-for="button, index in buttons" :key="index"
          :variant="button.variant"
          @click="() => action(dataInvoice, button.urlKey)"
          :disabled="loading.download"
          :class="button.class"
        >
          <b-spinner v-show="loading.download" class="mx-1" small/>
          <feather-icon :icon="button.icon" v-show="!loading.download"/> {{button.text}}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'modal-detail-invoice',
  props: ['dataInvoice', 'action'],
  data() {
    return {
      detailInvoice: [],
      detailkeys: Object.freeze([
        { title: this.$t('Cliente'), key: 'businessName' },
        { title: this.$t('Identificador Fiscal'), key: 'taxIdentificationNumber' },
        { title: this.$t('Número'), key: 'invoiceNumber' },
        { title: this.$t('Fecha emisión'), key: 'issueDate' },
        { title: this.$t('Fecha vencimiento'), key: 'dueDate' },
        { title: this.$t('Moneda'), key: 'currencyCode' },
        { title: this.$t('Monto neto'), key: 'netAmount', useFilter: true },
        { title: this.$t('Impuestos'), key: 'taxesAmount', useFilter: true },
        { title: this.$t('Total'), key: 'totalAmount', useFilter: true },
        { title: this.$t('Comentario'), key: 'notes' }
      ]),
      buttons: Object.freeze([
        { variant: 'success', icon: 'FileTextIcon', urlKey: 'pdf', text: 'PDF' },
        { variant: 'success', icon: 'FileIcon', urlKey: 'xml', text: 'XML', class: 'mx-1' },
        { variant: 'primary', icon: 'LinkIcon', urlKey: 'default', text: 'Documento' } // Documento debe cambiar a Bemmbo cuando se migre
      ]),
      loading: {
        download: false
      }
    }
  },
  computed: {
    ...mapGetters({
      generalLoading: 'getLoading'
    })
  },
  watch: {
    dataInvoice() {
      this.generateElemnts()
    },
    generalLoading: {
      handler () {
        this.loading.download = !!this.generalLoading['getInvoiceBillingDataCompanyById']
      },
      deep: true
    }
  },
  mounted() {
    this.generateElemnts()
  },
  methods: {
    generateElemnts() {
      this.detailInvoice = this.detailkeys.map(e => {
        e.text = this.dataInvoice[e.key] || '--'
        return e
      })
    },
    close() {
      this.$bvModal.hide('modal-detail-invoice')
    }
  }
}
</script>
