<template>
  <div>
    <b-card class="card-collections--container">
      <!-- Filter and download button -->
      <b-row v-show="!loading.first">
        <!-- Filters -->
        <b-col cols="12" md="9">
          <filter-swapper id="collections_swapper" :controlHeightButtons="controlHeight" class="col-12 px-0">
            <template #slot1>
              <form-render :key="keyFormRenderFilterCollection" :fields="fieldsFilter" :buttonSend="buttonSend" :form.sync="formFilter"
                @send="filterCollections()" containerButtonsClass="mt-2 pl-1 pl-md-0">
                <template #buttons>
                  <b-button variant="outline-light" class="ml-2 mr-2 mr-md-0" v-b-tooltip.hover :title="$t('Limpiar filtros')"
                  @click="cleanFilter" v-show="allowBtn" :disabled="loading.collection"><feather-icon icon="RefreshCwIcon"/></b-button>
                </template>
              </form-render>
            </template>
          </filter-swapper>
          <filter-select-group class="mt-2 mt-md-3" :options="optionsValues" :onChange="handleChangeFilter" :loadingStatus="statusRequest" :iconLabel="true"/>
        </b-col>
        <!-- Download button -->
        <b-col class="d-flex flex-wrap align-content-md-center mb-1 mb-md-0">
          <b-dropdown v-if="allowBtn || valueTags.length !== 0" class="ml-md-2" variant="outline-success" right>
            <template #button-content><feather-icon icon="DownloadIcon"/> {{$t('Exportar')}}</template>
            <b-dropdown-item @click="downloadPDF(true)">{{ $t('Exportar todo') }}</b-dropdown-item>
            <b-dropdown-item @click="downloadPDF()">{{ $t('Exportar contenido del filtro actual') }}</b-dropdown-item>
          </b-dropdown>
          <b-button v-else variant="outline-success" class="ml-md-2" @click="downloadPDF(false, true)"><feather-icon icon="DownloadIcon"/> {{$t('Exportar')}}</b-button>
        </b-col>
      </b-row>
      <!-- Table -->
      <table-render v-show="!loading.first" :key="keyTableRender" id="collection_list" :rows.sync="rows" :schema="schema"  :loading="loading.collection">
        <template #status="scope"><span class="table_dot--state" :class="colors[scope.rowdata.status.id]"></span> {{scope.rowdata.status.name}}</template>
        <template #totalAmount="scope">{{ $options.filters.currency(scope.rowdata.amountToCollect, 2, '$', '', true) || '--' }}</template>
        <template #comi="scope">{{ $options.filters.currency(scope.rowdata.amountCommission, 2, '$', '', true)|| '--' }}</template>
        <template #iva="scope">{{ $options.filters.currency(scope.rowdata.amountCommissionTax, 2, '$', '', true)|| '--' }}</template>
        <template #paid="scope">{{ $options.filters.currency(scope.rowdata.paymentAmount, 2, '$', '', true)|| '--'}}</template>
      </table-render>
      <pagination v-if="!loading.first" :pagination="pagination" :noDigits="false" :showSize="true"/>

      <!-- Skeleton -->
      <div class="table-render-skeleton" v-show="loading.first">
        <b-row class="px-1">
          <b-col class="d-flex" cols="12">
            <b-skeleton width="24%" height="2.3rem" class="mb-md-0"/>
            <b-skeleton width="23%" height="2.3rem" class="mb-md-0 mx-2"/>
            <b-skeleton width="5%" height="2.3rem" class="mb-md-0"/>
          </b-col>
          <b-col class="d-flex justify-content-md-end" cols="11">
            <b-skeleton width="9rem" height="2.5rem" class="mr-5 mb-md-0"/>
          </b-col>
          <b-col class="d-flex" cols="12">
            <b-skeleton width="2.5rem" height="2.5rem"/>
          </b-col>
        </b-row>
        <b-skeleton-table :rows="10" :columns="schema.length || 9" :table-props="{}" />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
import Swal from 'sweetalert2'

export default {
  props: ['company_id'],
  data() {
    return {
      baseService: new BaseServices(this),
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      buttonSend: { icon: 'SearchIcon', color: 'warning', title: 'Filtrar' },
      keyFormRenderFilterCollection: 0,
      fieldsFilter: [
        { fieldType: 'FieldDatepicker', name: 'date_range', label: this.$t('Fecha de Estado'), range: true, containerClass: 'col-6 col-md-3', showCalendarIcon: true, clearable: true },
        { fieldType: 'FieldInput', name: 'otShippingNum', label: this.$t('Número de envío/OT'), placeholder: 'Número de envío/OT', containerClass: 'col-6 col-md-3 container-info' }
      ],
      formFilter: {},
      loading: {
        first: true,
        collection: true
      },
      keyTableRender: 1,
      rows: [],
      schema: [],
      pagination: { page: 1, limit: 10, total: 0, loading: false },
      optionsValues: [
        {
          title: '',
          values: [
            { item: {value: 'all', group: 'isBilled', name: 'Todos'} },
            { item: {value: 1, group: 'isBilled', name: 'Facturados'} },
            { item: {value: 0, group: 'isBilled', name: 'No facturados'} }
          ]
        }
      ],
      colors: Object.freeze({
        1: 'ligth-blue--bg',
        2: 'mid-blue--bg',
        3: 'mid-blue--bg',
        4: 'ligth-green--bg',
        5: 'mid-blue--bg',
        6: 'ligth-red--bg'
      }),
      valueTags: [],
      statusRequest: null,
      allowBtn: false
    }
  },
  computed: {
    ...mapGetters({
      collectionDataCompany: 'getColletionsDataCompany'
    })
  },
  watch: {
    collectionDataCompany() {
      this.rows = this.collectionDataCompany.rows.map(colection => {
        return {
          ...colection,
          statusDate: this.$options.filters.UTCDateFormatter(colection.statusDate)
        }
      })

      if (this.loading.first && this.collectionDataCompany.seller && !!this.collectionDataCompany.seller.length) {
        this.schema.unshift({ label: 'Seller', key: 'delivery.company.name'})
        const sellers = this.collectionDataCompany.seller.map(seller => {
          return {
            ...seller,
            text: seller.name
          }
        })
        this.fieldsFilter.push({fieldType: 'FieldSelect', name: 'sellerId', label: 'Seller', useLabel: true, clearable: true, options: sellers, containerClass: 'col-6 col-md-3' })
      }
      this.loading.first = false
      this.loading.collection = false
      this.pagination.page = this.collectionDataCompany.pagination.current_page
      this.pagination.total = this.collectionDataCompany.pagination.total_items
      this['pagination.limit'] = this.collectionDataCompany.pagination.total_pages
      this.buttonSend.disabled = false
    },
    'pagination.page'() {
      this.getCollectionData()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getCollectionData()
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.schema = [
        { label: 'Courier', key: 'delivery.carrier.name', style: { width: '15%' }},
        { label: 'n° de envío', key: 'delivery.importedId', style: { width: '12%' }},
        { label: 'OT', key: 'delivery.trackingNr', style: { width: '12%' }},
        { label: 'Estado', key: 'status', useSlot: true},
        { label: 'Fecha estado', key: 'statusDate'},
        { label: 'Monto recaudo', key: 'totalAmount', useSlot: true},
        { label: 'Comisión', key: 'comi', useSlot: true},
        { label: 'IVA', key: 'iva', useSlot: true},
        { label: 'Pagado', key: 'paid', useSlot: true}
      ]
      this.getCollectionData()
    },
    getCollectionData() {
      this.loading.collection = true
      this.buttonSend.disabled = true
      const params = {
        company_id: this.company_id
      }
      const queryParams = {
        'page': this.pagination.page,
        'size': this.pagination.limit,
        ...this.generateFilters()
      }
      this.$store.dispatch('fetchServiceStatusOnError', { name: 'getColletionsDataCompany', params, queryParams,
        onSuccess: () => {
          if (!!this.valueTags.length) this.statusRequest = 'success'
        },
        onError: (err) => {
          if (!!this.valueTags.length) this.statusRequest = 'error'
          this.handleErrorColectionData(err)
        }
      })
    },
    handleErrorColectionData(err) {
      const {subtitle, detail} = this.handlerStandartError(err)
      this.$alert(`${subtitle}<small>${detail}</small>`, null, 'Error en la pestaña de Recaudos contra entrega')
      this.buttonSend.disabled = false
      this.loading.collection = false
    },
    filterCollections() {
      if (!!Object.keys(this.formFilter).length && !this.allowBtn) this.allowBtn = true
      this.pagination.page > 1 ? this.pagination.page = 1 : this.getCollectionData()
    },
    cleanFilter() {
      this.formFilter = {}
      this.allowBtn = false
      this.pagination.page > 1 ? this.pagination.page = 1 : this.getCollectionData()
    },
    downloadPDF(filter, checkFilters = false) {
      this.$loadingAlert({text: 'Procesando exportación Resumen Recaudos...'})
      const params = {
        company_id: this.company_id
      }
      const queryParams = {
        ...this.generateFilters(filter, checkFilters)
      }
      const props = {
        name: 'downloadColletionsDataCompany',
        params,
        fileName: `ResumenRecaudos${new Date().toLocaleDateString().split('/').join('-')}`,
        showToast:  true,
        queryParams,
        onSuccess: this.closeAlert,
        onError: this.closeAlert
      }
      this.$store.dispatch('fetchServiceGenerateExcel', props)
    },
    closeAlert() {
      Swal.update({customClass: {}})
      Swal.close()
    },
    handleChangeFilter (values) {
      this.valueTags = values
      this.getCollectionData()
    },
    generateFilters(noFilter = false, checkFilters = false) {
      if (noFilter) return {}
      const filter = {}
      const { date_range, sellerId, otShippingNum } = this.formFilter
      if (!(checkFilters && !this.allowBtn)) {
        if (date_range) {
          filter['filter[statusDateSince]'] = this.$options.filters.moment(date_range.start, 'YYYY-MM-DD') || ''
          filter['filter[statusDateUntil]'] = this.$options.filters.moment(date_range.end, 'YYYY-MM-DD') || ''
        }
        if (sellerId) {
          filter['filter[sellerId]'] = sellerId.id
        }
        if (otShippingNum) {
          filter['filter[otShippingNum]'] = otShippingNum
        }
      }
      if (this.valueTags.length) {
        this.statusRequest = 'load'
        this.valueTags.forEach(val => {
          if (val.value !== 'all') filter[`filter[${val.group}]`] = val.value
        })
      }
      return filter
    }
  }
}
</script>

<style lang="scss">
.card-collections--container > .card-body{
  padding-top: 0;
}
</style>
