import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  { name: 'saveManageBillingContact', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/business-data`, method: 'post' },
  { name: 'getShippersByOrganization', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers`, method: 'get'},
  { name: 'getShipper', url: `${environment.dubaiApiUrl}/shippers/{shipper_id}`, method: 'get' },
  { name: 'getBillableCompanies', url: `${environment.miamiApiUrl}/v1/billing/countries/{country_id}/companies-billable`, method: 'get', notCancel: true },
  { name: 'getOrganization', url: `${environment.dubaiApiUrl}/organizations/{id}`, method: 'get'}
]

export default class BillingService {
  constructor(context) {
    this.context = context
  }
  async callService(name, queryParams = {}, params = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params)
  }

  async callMultipleServices(callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }
  setEnvironment () {
    return environment.platformUrl
  }
  getCustomEnvironment (name) {
    return environment[name]
  }
}
