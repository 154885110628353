<template>
  <b-modal
    id="modal-upload-invoice"
    :title="$t('Subir factura')"
    :ok-title="$t(loading.uploadData ? 'Cargando archivo' : 'Confirmar')"
    ok-variant="success"
    @ok="handleUpload"
    ok-only
    :ok-disabled="loading.uploadData"
    modal-class="dialog-900"
    no-close-on-esc
    no-close-on-backdrop
    centered
    @close="close"
    accept=".xls,.xlxs"
  >
    <div class="row mt-0 pt-0">
      <b-col class="px-1 pt-2 col" v-if="form.fileInvoice" v-cloak>
        <b-input-group class="w-100 p-2 file-drop-area">
          <b-form-input
              placeholder="File name..."
              v-model="form.fileInvoice.name"
              required
              class="bg-gray-input border-0"
          />
          <b-input-group-append class="bg-gray-input">
            <b-button variant="outline" class="border-0" size="sm" @click="removeFileInvoice()" :disabled="loading.uploadData">
              <feather-icon icon="XIcon" size="20"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col class="px-1 pt-2 col" v-if="!form.fileInvoice">
        <div class="file-drop-area" >
          <input type="file" name="file" id="file" class="file-input invisible" @change="changeFileInvoice" accept=".xls, .xlsx"/>
          <label for="file" class="w-100 text-center cursor-pointer m-0">
            <feather-icon v-if="!loading.uploadData" icon="UploadCloudIcon" size="30" class="mt-1" />
            <p v-if="!loading.uploadData" class="mb-1">Subir Archivo</p>
            <p class="mt-1 p-2" v-if="loading.uploadData"><b-spinner small  /> {{ $t('Cargando') }}</p>
          </label>
        </div>
      </b-col>
    </div>
    <div>
      <p><small>El archivo solo puede ser *.xls o *.xlsx y el nombre debe cumplir con el siguiente formato: <span class="text-danger">[NÚMERO FACTURA]_[ID EMPRESA ENVÍAME]</span></small></p>
    </div>
    <div>
    </div>
  </b-modal>
</template>

<script>
import BaseServices from '@/store/services/index'

export default {
  name: 'modal-upload-invoice',
  props: ['company_id', 'dataInvoice', 'getInvoiceData'],

  data() {
    return {
      file: null,
      baseService: new BaseServices(this),
      form: {
        fileInvoice: null
      },
      loading: {
        uploadData: false
      }
    }
  },
  methods: {
    handleUpload(bvModalEvent) {
      this.loading.uploadData = true
      bvModalEvent.preventDefault()
      if (!this.validateFile(this.form.fileInvoice)) return

      const params = {
        company_id: this.company_id,
        invoice_number: this.dataInvoice.invoiceNumber
      }
      const data = {
        file: this.form.fileInvoice
      }
      this.baseService.callUploadFile('uploadBillFileBillingDataCompany', data, params)
        .then(response => {
          this.$success(this.$t('msg-exito-guardar'))
          this.$emit('getInvoiceData')
          this.close()
        })
        .catch((err) => {
          const errorDetail = err.errors.map(errObj => {
            return `<br><br>${errObj.name}<br>${err.message}`
          })
          this.$alert(`<b>Ocurrió un problema al cargar los datos</b>${errorDetail}`)
          console.error(err)
          this.loading.uploadData = false
        })
    },
    close() {
      this.form.fileInvoice = null
      this.loading.uploadData = false
      this.$bvModal.hide('modal-upload-invoice')
    },
    changeFileInvoice(e) {
      this.form.fileInvoice = e.target.files[0]
    },
    removeFileInvoice() {
      this.form.fileInvoice = null
    },
    validateFile (file) {
      const regx = /^[0-9_]*$/
      if (!file) {
        this.showModalError('Debes seleccionar un archivo para subir')
        return false
      }
      if (file.name.split('.')[0].split('_').length < 2 || !regx.test(file.name.split('.')[0])) {
        this.showModalError('Estructura incorrecta en el nombre')
        return false
      }
      return true
    },
    showModalError (detail) {
      this.$alert(detail)
      this.loading.uploadData = false
    }
  }, setup() {
  }
}
</script>

<style lang="scss" scoped>
  div.file-drop-area {
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    input.file-input {
      position: absolute;
      height: 0;
    }
  }
  .bg-gray-input {
    background-color: #f7f7f9 !important
  }
</style>
